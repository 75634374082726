import { useState, createContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
	const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

	const state = {
		isLoggedIn,
	};

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: state.isLoggedIn,
				setIsLoggedIn: setIsLoggedIn,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};
