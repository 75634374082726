import axios from "axios";
export const LoginUser = async (userEmail, password) => {
	const response = { data: null, err: null };
	try {
		let { data } = await axios.post(
			`${process.env.REACT_APP_AUTH_API_PATH}/login/`,
			{
				email: userEmail,
				password: password,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		response.data = data;
	} catch (err) {
		response.err = err.response.data.detail;
	}

	return response;
};

export const SignoutUser = async () => {
	let token = localStorage.getItem("token");

	let result = await axios.post(
		`${process.env.REACT_APP_AUTH_API_PATH}/signout/`,
		{},

		{
			headers: {
				Authorization: `Token ${token}`,
			},
		}
	);
	return result;
};
//