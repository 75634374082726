import "./sidenavbar.css";
import React from "react";
import { useContext } from "react";

//router
import { NavLink } from "react-router-dom";

//mui
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

//assets
import logo from "assets/img/logo.png";

//contexts
import { LanguageContext } from "contexts/LanguageContext";

function SideNavbar({ isCollapsed, setisCollapsed }) {
	const { langData } = useContext(LanguageContext);

	const handleCollapse = () => {
		setisCollapsed(!isCollapsed);
	};
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				borderRadius: "10px",
				overflow: "hidden",
				display: "flex",
				alignItems: "center",
			}}>
			<div id='sidenavbar_container'>
				<div
					className='logo_section'
					style={{
						width: "100%",
						textAlign: "center",
						marginBottom: isCollapsed ? "8.75rem" : "30%",
						marginTop: isCollapsed ? "2rem" : "8%",
						padding: "10%",
					}}>
					<img
						style={{ width: isCollapsed ? "60%" : "27%" }}
						src={logo}
						alt=''
					/>
					{!isCollapsed && (
						<p
							style={{
								marginTop: "0px",
								color: "#FF8B51",
								fontWeight: "bolder",
								fontSize: "100%",
							}}>
							{langData?.title}
						</p>
					)}
				</div>
				<NavLink
					style={({ isActive }) => {
						return {
							color: isActive ? "#FF8B51" : "black",
							borderLeft: isActive ? "5px solid #FF8B51" : "5px solid #edf4ff",
						};
					}}
					className='nav_links'
					end
					to='user-list'>
					<PeopleOutlineOutlinedIcon
						sx={{ width: isCollapsed ? "90%" : "18%" }}
					/>
					{!isCollapsed && (
						<span style={{ fontSize: "90%", paddingLeft: "5%" }}>
							{langData.sidenavbar.userlist}
						</span>
					)}
				</NavLink>
				<NavLink
					// style={{ marginBottom: "15rem", borderLeft: " 5px solid #ffffff" }}
					style={({ isActive }) => {
						return {
							color: isActive ? "#FF8B51" : "black",
							borderLeft: isActive ? "5px solid #FF8B51" : "5px solid #edf4ff",
							marginBottom: "15rem",
						};
					}}
					className='nav_links'
					to='jurisdiction'>
					<DashboardOutlinedIcon sx={{ width: isCollapsed ? "90%" : "18%" }} />
					{!isCollapsed && (
						<span
							style={{
								paddingLeft: "5%",
								fontSize: "90%",
								wordBreak: "break-word",
							}}>
							{langData.sidenavbar.jurisdiction}
						</span>
					)}
				</NavLink>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					{isCollapsed ? (
						<ArrowCircleRightOutlinedIcon onClick={handleCollapse} />
					) : (
						<ArrowCircleLeftOutlinedIcon onClick={handleCollapse} />
					)}
				</div>
			</div>
		</div>
	);
}

export default SideNavbar;
