const data = {
	title: "Booth Vijay Abhiyan",
	loginPage: {
		signIn: "Sign In",
		email: "Email address",
		password: "Password",
		forgotPassword: "Forgot Password ?",
	},
	sidenavbar: {
		userlist: "User List",
		jurisdiction: "Jurisdiction",
	},
	attributes: {
		state: "State",
		district: "District",
		pc: "PC",
		ac: "AC",
		mandal: "Mandal",
		skendra: "S.Kendra",
		booth: "Booth",

		search: "Search",
		clearAll: "Clear All",
	},
	summarysection: {
		summary: "Summary",
		total: "Total",
		verified: "Verified",
		unverified: "Unverified",
		unauthorized: "Unauthorized",
		approved: "Approved",
		yetToApproved: "Yet to Approved",
		addNew: "Add New",
		userName: "User Name",
	},

	users: {
		listofUsers: "List of Users",
		name: "NAME",
		phone: "PHONE",
		photo: "PHOTO",
		role: "ROLE",
		jurisdiction: "JURISDICTION",
		authorization: "AUTHORIZATION",
		approval: "APPROVAL",
		verificationStatus: "VERIFICATION STATUS",
		verifiedBy: "VERIFIED BY",
		actions: "ACTIONS",
		searchName: "Search Name",
		userStates: {
			pending: "Pending",
			unauthorized: "Unauthorized",
			authorized: "Authorized",
			approved: "Approved",
		},
		userActionBtn: {
			saveChanges: "Save Changes",
			cancel: "Cancel",
		},
		editUser: "Edit User",
		enterUserName: "Enter User Name",
	},

	jurisdictions: {
		booth: "Booth",
		mandal: "Mandal",
		shaktiKendra: "Shakti Kendra",
	},
	dashboardActions: {
		language: "Language",
		english: "English",
		logout: "Logout",
	},
	roles: {
		all: "All",
		boothAdhiyaksh: "Booth Adhiyaksh",

		boothCommitee: "Booth Commitee",

		mandalAdhiyaksh: "Mandal Adhiyaksh",

		mandalCommitee: "Mandal Commitee",

		sahPramukh: "Sah Pramukh",
		shaktiKendraPrabhari: "Shakti Kendra Prabhari",
		shaktiKendraPramukh: "Shakti Kendra Pramukh",
	},
};
export default data;
