import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "contexts/AuthContext";
import { AlertProvider } from "contexts/AlertContext";
import { DataProvider } from "contexts/DataContext";
import { LanguageProvider } from "contexts/LanguageContext";
import GlobalCssOverride from "ThemeProvider";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<GlobalCssOverride>
			<AuthProvider>
				<AlertProvider>
					<DataProvider>
						<LanguageProvider>
							<Router>
								<App />
							</Router>
						</LanguageProvider>
					</DataProvider>
				</AlertProvider>
			</AuthProvider>
		</GlobalCssOverride>
	</React.StrictMode>
);
