import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
	shadows: Array(25).fill("none"),
	palette: {
		primary: {
			main: "#FF8B51",
		},
		secondary: {
			main: "#2D9CDB",
			dark: "#143566",
		},
	},
	typography: {
		fontFamily: ["Montserrat", "sans-serif"].join(","),
	},

	components: {
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					textTransform: "none",
					fontFamily: "Montserrat",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: "#E5E5E5",
				},
				h4: {
					fontSize: "2rem!important",
					fontWeight: "600!important",
				},
				h2: {
					fontSize: "2.25rem!important",
					fontWeight: "600!important",
				},
				h3: {
					fontSize: "2.125rem!important",
					fontWeight: "600!important",
				},
				a: {
					textDecoration: "none",
					color: "inherit",
				},
			},
		},
	},
});

export default function GlobalCssOverride(props) {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{props.children}
		</ThemeProvider>
	);
}
