import { useState, createContext } from "react";

export const DataContext = createContext();

export const DataProvider = (props) => {
	const [stateData, setstateData] = useState("");
	const [membersData, setmembersData] = useState("");

	return (
		<DataContext.Provider
			value={{
				stateData: stateData,
				setstateData: setstateData,

				membersData: membersData,
				setmembersData: setmembersData,
			}}>
			{props.children}
		</DataContext.Provider>
	);
};
