import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";

//imports
import SideNavbar from "components/SideNavbar";
import TopUserBar from "components/TopUserBar";
import ErrorBoundary from "components/ErrorBoundary";

//mui
import LinearProgress from "@mui/material/LinearProgress";

const AppLayout = () => {
	const [isCollapsed, setisCollapsed] = useState(false);
	return (
		<div
			style={{
				display: "flex",
				height: "100%",
				minHeight: "100vh",
				width: "100%",
			}}>
			<div
				style={{
					width: isCollapsed ? "6%" : "14%",
					display: "flex",
					flexDirection: "column",
				}}>
				<SideNavbar
					isCollapsed={isCollapsed}
					setisCollapsed={setisCollapsed}
				/>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: isCollapsed ? "95%" : "86%",
				}}>
				<div>
					<TopUserBar />
				</div>
				<Suspense fallback={<LinearProgress />}>
					<ErrorBoundary>
						<Outlet />
					</ErrorBoundary>
				</Suspense>
			</div>
		</div>
	);
};

export default AppLayout;
