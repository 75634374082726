import React from "react";
import { useContext } from "react";

//assets
import user from "assets/img/user.png";

//context
import { AuthContext } from "contexts/AuthContext";
import { LanguageContext } from "contexts/LanguageContext";

//utils
import { SignoutUser } from "api/AuthAction";

//mui
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function TopUserBar() {
	const { setIsLoggedIn } = useContext(AuthContext);
	const { lang, setLang, langData, setLangData } = useContext(LanguageContext);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLanguageChange = () => {
		if (lang === "english") setLang("oriya");
		else setLang("english");
	};

	React.useEffect(() => {
		import(`languages/${lang}`).then((data) => {
			setLangData(data.default);
		});
		localStorage.setItem("lang", lang);
	}, [lang]);
	return (
		<div>
			<div
				style={{
					border: "2px solid #E5E4E2",
					backgroundColor: "white",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "99%",
					margin: "auto",
					padding: "0.5%",
					borderRadius: "10px",
					marginTop: "5px",
				}}>
				<img
					style={{ width: "2.5%" }}
					src={user}
					alt=''
				/>
				<span style={{ marginLeft: "5px", fontSize: "80%" }}>
					{localStorage.getItem("username")}
				</span>
				<ArrowDropDownIcon
					id='basic-button'
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					style={{ cursor: "pointer" }}
				/>
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}>
					<MenuItem
						onClick={() => {
							handleLanguageChange();
							handleClose();
						}}>
						{langData.dashboardActions.language} :
						{lang === "english" ? " Oriya" : " English"}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setIsLoggedIn(false);
							SignoutUser();
							localStorage.removeItem("username");
							localStorage.removeItem("token");
						}}>
						{langData.dashboardActions.logout}
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
}

export default TopUserBar;
