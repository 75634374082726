import React, { Suspense, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";

import PrivateRoute from "components/PrivateRoute";
import AlertBar from "components/AlertBar";
import AppLayout from "layout";
import trackPathForAnalytics from "TrackPageForAnalytics";

const LoginView = React.lazy(() => import("pages/auth/login"));
const MainView = React.lazy(() => import("pages"));
const UserListView = React.lazy(() => import("pages/app/user_list"));
const JurisdictionView = React.lazy(() => import("pages/app/jurisdiction"));
const ErrorView = React.lazy(() => import("pages/error"));

function App() {
	const { pathname, search } = useLocation();
	const definePathTitle = (s) => {
		let arr = s.split("/");
		if (arr[2]) return arr[2];
		return arr[1];
	};

	const analytics = useCallback(() => {
		trackPathForAnalytics({
			path: pathname,
			search: search,
			title: definePathTitle(pathname),
		});
	}, [pathname, search]);

	useEffect(() => {
		analytics();
	}, [analytics]);
	return (
		<div>
			<Routes>
				<Route
					path='/login'
					element={<LoginView />}
				/>
				<Route element={<PrivateRoute />}>
					<Route
						path='/'
						element={<MainView />}
					/>
					<Route
						path='/app'
						element={<AppLayout />}>
						<Route
							//
							path='user-list'
							element={<UserListView />}
						/>
						<Route
							path='jurisdiction'
							element={<JurisdictionView />}
						/>
					</Route>
				</Route>
				<Route
					path='*'
					element={<ErrorView />}
				/>
			</Routes>
			<AlertBar />
		</div>
	);
}

export default App;
