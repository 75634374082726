import { useState, createContext } from "react";

import data from "../languages/english";

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
	const [lang, setLang] = useState(localStorage.getItem("lang") || "english");
	const [langData, setLangData] = useState(data);

	return (
		<LanguageContext.Provider
			value={{
				lang: lang,
				setLang: setLang,

				langData: langData,
				setLangData: setLangData,
			}}
		>
			{props.children}
		</LanguageContext.Provider>
	);
};
