import React, { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AlertContext } from "contexts/AlertContext";

function Alert(props) {
	return (
		<MuiAlert
			elevation={6}
			variant='filled'
			{...props}
		/>
	);
}

const AlertBar = () => {
	const { msg, severity, hideAlert } = useContext(AlertContext);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		msg ? setOpen(true) : setOpen(false);
	}, [msg]);

	const handleClose = () => {
		hideAlert();
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			open={open}
			onClose={handleClose}
			autoHideDuration={5000}>
			{msg && (
				<Alert
					onClose={handleClose}
					severity={severity}>
					{msg}
				</Alert>
			)}
		</Snackbar>
	);
};

export default AlertBar;
