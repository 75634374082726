import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import { AuthContext } from "contexts/AuthContext";

const PrivateRoute = () => {
	const { isLoggedIn } = useContext(AuthContext);
	const location = useLocation();
	return isLoggedIn ? (
		<Outlet />
	) : (
		<Navigate
			to='/login'
			state={{ lastLocation: location }}
			replace
		/>
	);
};

export default PrivateRoute;
